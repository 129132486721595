<template>
	<!-- 对话收藏 -->
	<div>
		<a-card :bordered="false" class="header-solid h-full mb-24" style="overflow: hidden" :bodyStyle="{ padding: '16px' }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12">
						<h5 class="font-semibold m-0">对话收藏</h5>
					</a-col>
				</a-row>
			</template>
			<div class="body_box">
				<div class="list_box" v-if="chatData.length">
					<div class="item" v-if="item.collect" v-for="(item, index) in chatData" :key="'chat_list_' + index">
						<div class="line">
							<div class="left color1">问</div>
							<div class="right">{{ item.message }}</div>
						</div>
						<div class="line">
							<div class="left color2">答</div>
							<div class="right">
								<v-md-editor v-model="item.response" mode="preview"></v-md-editor>
							</div>
						</div>
						<div class="info">
							<div class="left" @click="cancalCollect(item)">
								<a-icon type="heart" theme="filled" :style="{ color: '#FF3434' }" />
								<span>取消收藏</span>
							</div>
							<div class="right">{{ item.createtime }}</div>
						</div>
					</div>
				</div>
				<div class="m-20" v-else>
					<template>
						<a-empty description="暂无数据" />
					</template>
				</div>
			</div>
		</a-card>
	</div>
</template>

<script>
	import { Debounce } from "@/utils/tools"
	export default {
		data() {
			return {
				chatData: [],
				page: 1,
				pagesize: 10
			}
		},
		created() {
			this.getChatList()
			window.addEventListener("scroll", this.handleScroll, false)
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll, false)
		},
		methods: {
			// 滚动事件
			handleScroll: Debounce(
				function () {
					let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
					let clientHeight = document.documentElement.clientHeight
					let scrollHeight = document.documentElement.scrollHeight
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						this.page++
						this.getChatList()
					}
				},
				20,
				false
			),
			// 取消收藏
			cancalCollect(item) {
				if (!item.type || !item.item_id) return
				this.$http("chat.collect", { type: item.type, msg_id: item.item_id }).then(res => {
					if (res.code === 1) {
						this.chatData = this.chatData.filter(obj => {
							return obj.id !== item.id
						})
						this.$message.success(res.msg)
					}
				})
			},
			// 获取收藏的对话
			getChatList() {
				this.$http("chat.collectList", {
					type: "all",
					page: this.page,
					pagesize: this.pagesize
				}).then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							const arr = res.data.map(item => {
								return { ...item, collect: true }
							})
							this.chatData = this.chatData.concat(arr)
						} else {
							this.page--
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.body_box {
		.list_box {
			.item {
				background: #fafafa;
				border: 1px solid #f5f5f5;
				border-radius: 12px;
				padding: 16px;
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}

				.line {
					display: flex;
					margin-bottom: 10px;
					font-size: 14px;

					.left {
						width: 20px;
						height: 20px;
						line-height: 20px;
						text-align: center;
						color: #fff;
						border-radius: 4px;
						margin-right: 10px;
						white-space: nowrap;

						&.color1 {
							background: #1890ff;
						}
						&.color2 {
							background: #23b571;
						}
					}

					.right {
						flex: 1;

						::v-deep .v-md-editor__right-area {
							width: auto;
							min-width: 0;
						}

						::v-deep .vuepress-markdown-body {
							padding: 0;
							background: #fafafa;
						}
					}
				}

				.info {
					padding-left: 30px;
					margin-top: 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #8c8c8c;
					.left {
						display: flex;
						align-items: center;
						cursor: pointer;

						span {
							margin-left: 8px;
						}
					}
				}
			}
		}
	}
</style>
