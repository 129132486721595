<template>
	<!-- 个人中心 基本信息卡片 -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }" :headStyle="{ paddingRight: 0 }">
		<template #title>
			<h6 class="font-semibold m-0">基本信息</h6>
		</template>
		<a-button type="link" slot="extra">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827" />
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827" />
			</svg>
		</a-button>

		<a-descriptions title="" :column="1">
			<a-descriptions-item label="昵称">{{ userInfo.nickname }}</a-descriptions-item>
			<a-descriptions-item label="手机号">{{ userInfo.mobile ? userInfo.mobile : "无" }}</a-descriptions-item>
			<a-descriptions-item label="UID">{{ userInfo.user_id }}</a-descriptions-item>
			<a-descriptions-item label="创作身份">{{ userInfo.is_vip === 1 ? userInfo.vip_expiretime_text : "普通用户" }}</a-descriptions-item>
			<a-descriptions-item label="分销商">{{ userInfo.reseller ? userInfo.reseller.reseller_json.name : "无" }}</a-descriptions-item>
			<a-descriptions-item label="加入时间">{{ joinTime }}</a-descriptions-item>
		</a-descriptions>
	</a-card>
</template>

<script>
	import { timestampToTime } from "@/utils/tools.js"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {
				joinTime: ""
			}
		},
		computed: {
			...mapGetters("user", ["userInfo"])
		},
		created() {
			this.joinTime = timestampToTime(this.userInfo.createtime)
		}
	}
</script>
