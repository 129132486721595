<!-- 个人中心 -->

<template>
	<div>
		<div class="profile-nav-bg" :style="{ backgroundImage: `url(${require('@/assets/imgs/bg-profile.jpg')})`, height: '200px' }"></div>
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" :src="userInfo.avatar" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ userInfo.nickname }}</h4>
							<p>积分：{{ userInfo.usable || 0 }}</p>
							<p v-if="userInfo.is_vip === 1">VIP到期时间：{{ userInfo.vip_expiretime_text }}</p>
							<p v-if="userInfo.reseller">{{ userInfo.reseller.reseller_json.name }}到期时间：{{ userInfo.reseller.expiretime_text }}</p>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<a-tooltip placement="bottom" style="margin-right: 10px">
							<template slot="title">
								<span>卡密兑换</span>
							</template>
							<a-button type="primary" size="small" icon="gift" @click="cdkey.show = true"></a-button>
						</a-tooltip>
						<a-tooltip placement="bottom" style="margin-right: 10px" v-if="tabsId === 2">
							<template slot="title">
								<span>复制邀请链接</span>
							</template>
							<a-button type="primary" size="small" icon="usergroup-add" @click="copyShareLink"></a-button>
						</a-tooltip>
						<a-radio-group v-model="tabsId" size="small">
							<a-radio-button :value="item.id" v-for="(item, index) in tabsList" :key="'tab_' + item.id">{{ item.title }}</a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</template>
		</a-card>

		<div v-if="tabsId === 1">
			<a-row type="flex" :gutter="24">
				<a-col :span="24" :md="8" class="mb-24">
					<AccountInfo></AccountInfo>
				</a-col>
				<a-col :span="24" :md="8" class="mb-24">
					<BaseInfo></BaseInfo>
				</a-col>
				<a-col :span="24" :md="8" class="mb-24">
					<InviteInfo></InviteInfo>
				</a-col>
			</a-row>
		</div>
		<div v-if="tabsId === 2">
			<a-row :gutter="24" type="flex">
				<a-col :span="24" class="mb-24">
					<PlaceTable></PlaceTable>
				</a-col>
			</a-row>
		</div>
		<div v-if="tabsId === 3">
			<a-row :gutter="24" type="flex">
				<a-col :span="24" class="mb-24">
					<WriteTable></WriteTable>
				</a-col>
			</a-row>
		</div>
		<div v-if="tabsId === 4">
			<a-row :gutter="24" type="flex">
				<a-col :span="24" class="mb-24">
					<ChatCollect></ChatCollect>
				</a-col>
			</a-row>
		</div>

		<a-modal v-model="cdkey.show" title="卡密兑换" :centered="true" :maskClosable="false" cancelText="取消" okText="确定" :confirmLoading="cdkey.loading" @ok="cdkeyConfirm">
			<div class="cdkey_modal">
				<a-input v-model="cdkey.value" :allowClear="true" placeholder="请输入兑换码">
					<a-icon slot="prefix" type="meh" />
				</a-input>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import AccountInfo from "../components/Custom/AccountInfo.vue"
	import BaseInfo from "../components/Custom/BaseInfo.vue"
	import InviteInfo from "../components/Custom/InviteInfo.vue"
	import PlaceTable from "../components/Custom/PlaceTable.vue"
	import WriteTable from "../components/Custom/WriteTable.vue"
	import ChatCollect from "../components/Custom/ChatCollect.vue"

	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: {
			AccountInfo,
			BaseInfo,
			InviteInfo,
			PlaceTable,
			WriteTable,
			ChatCollect
		},
		data() {
			return {
				tabsId: 1,
				tabsList: [
					{ id: 1, title: "信息总览" },
					{ id: 2, title: "分销中心" },
					{ id: 3, title: "创作稿件" },
					{ id: 4, title: "对话收藏" }
				],
				cdkey: {
					show: false,
					loading: false,
					value: ""
				}
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
			...mapState("app", ["sign"])
		},
		watch: {},
		created() {
			this.getUserInfo()
			this.getStatisticInfo()
		},
		methods: {
			...mapActions("user", ["getUserInfo", "getStatisticInfo"]),
			// 兑换
			cdkeyConfirm() {
				if (!this.cdkey.value) return
				this.cdkey.loading = true
				this.$http("user.cdkey", {
					crypto: this.cdkey.value,
					platform: "Web"
				})
					.then(res => {
						res.code === 1 && this.$message.success(res.msg)
						this.cdkey.value = ""
						this.cdkey.loading = false
						this.cdkey.show = false
					})
					.catch(err => {
						this.$message.error("兑换失败")
						this.cdkey.value = ""
						this.cdkey.loading = false
						this.cdkey.show = false
					})
			},
			// 复制
			async copyShareLink() {
				const spm = this.userInfo.id + ".1.0.4.3"
				const link = window.location.origin + window.location.pathname + `?${this.sign}#/?share=${spm}`
				try {
					await navigator.clipboard.writeText(link)
					this.$message.success("已复制到剪切板")
				} catch (err) {
					this.$message.error("复制失败")
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.cdkey_modal {
		::v-deep .ant-input {
			letter-spacing: 1px;

			&::placeholder {
				font-weight: normal;
			}
		}
	}
</style>
